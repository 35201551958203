import React from "react";
import Header from "../components/Header";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Gallery() {
  return (
    <>
      <section className="section--gallery">
        <Header />
        <div className="gallery">
          <div className="gallery__image">
            <img src={require("../images/h5.jpg")} alt="burgers'n whiskey" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/v4.jpg")} alt="mohito" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/h6.jpg")} alt="pizza" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/v5.jpg")} alt="smokin shot" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/v2.jpg")} alt="pizza" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/h3.jpg")} alt="burgers and fries" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/h8.jpg")} alt="bartender" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/homeBg2.jpg")} alt="burger" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/h7.jpg")} alt="shots" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/v3.jpg")} alt="alien shot" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/v1.jpg")} alt="blue lagoon" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/h4.jpg")} alt="burgers" />
          </div>
          <div className="gallery__image">
            <img src={require("../images/homeBg.jpg")} alt="logo burger" />
          </div>
        </div>
        <footer className="photos">
          <FontAwesomeIcon
            icon={faCamera}
            style={{ color: "rgba(174, 116, 3, 255)" }}
          />{" "}
          Fot.
          <a
            className="link"
            href="https://www.facebook.com/hucii24"
            target="__blank"
          >
            <FontAwesomeIcon
              icon={faFacebook}
              className="fa-icon--contact icon--facebook"
            />
            Łukasz Hucał
          </a>
          <a
            className="link"
            href="https://www.instagram.com/_hucii/"
            target="__blank"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              className="fa-icon--contact icon--instagram"
            />
            _hucii
          </a>
        </footer>
      </section>
    </>
  );
}
