import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPizzaSlice,
  faBurger,
  faSeedling,
  faUtensils,
  faJar,
  faGlassWater,
  faMartiniGlass,
  faDrumstickBite,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import { pizzaData } from "../data/pizzaData";
import { burgerData } from "../data/burgerData";
import { saladData } from "../data/saladData";
import { snackData } from "../data/snackData";
import { mainDishData } from "../data/mainDishData";
import { drinkData } from "../data/drinkData";
import { sodaData } from "../data/sodaData";
import { shotData } from "../data/shotData";
import { sauceData } from "../data/sauceData";

export default function Menu() {
  type Menu = {
    name?: string;
    polishName?: string;
    englishName?: string;
    price?: number;
    polishIngredients?: string;
    englishIngredients?: string;
    smallPrice?: number;
    largePrice?: number;
  };

  const [menu, setMenu] = useState<Menu[]>([]);
  const [activeMenu, setActiveMenu] = useState<string>("burger");

  const handleClick: Function = (data: Menu[], divId: string) => {
    setMenu(data);
    setActiveMenu(divId);
  };

  useEffect(() => {
    setMenu(burgerData);
  }, []);

  return (
    <>
      <section className="section--menu">
        <Header />
        <div className="categories__container">
          <div
            style={
              activeMenu === "pizza"
                ? {
                    color: "rgba(174, 116, 3, 255)",
                  }
                : {}
            }
            className="category--pizza"
            onClick={() => handleClick(pizzaData, "pizza")}
          >
            <FontAwesomeIcon icon={faPizzaSlice} />
            <p>Pizza</p>
          </div>
          <div
            style={
              activeMenu === "burger" ? { color: "rgba(174, 116, 3, 255)" } : {}
            }
            className="category--burger"
            onClick={() => handleClick(burgerData, "burger")}
          >
            <FontAwesomeIcon icon={faBurger} />
            <p>Burgery</p>
          </div>
          <div
            style={
              activeMenu === "salad" ? { color: "rgba(174, 116, 3, 255)" } : {}
            }
            className="category--salad"
            onClick={() => handleClick(saladData, "salad")}
          >
            <FontAwesomeIcon icon={faSeedling} />
            <p>Sałatki</p>
          </div>
          <div
            style={
              activeMenu === "mainDish"
                ? { color: "rgba(174, 116, 3, 255)" }
                : {}
            }
            className="category--utensils"
            onClick={() => handleClick(mainDishData, "mainDish")}
          >
            <FontAwesomeIcon icon={faUtensils} />
            <p>Dania</p>
          </div>
          <div
            style={
              activeMenu === "snack" ? { color: "rgba(174, 116, 3, 255)" } : {}
            }
            className="category--sides"
            onClick={() => handleClick(snackData, "snack")}
          >
            <FontAwesomeIcon icon={faDrumstickBite} />
            <p>Przekąski</p>
          </div>
          <div
            style={
              activeMenu === "sauce" ? { color: "rgba(174, 116, 3, 255)" } : {}
            }
            className="category--sauce"
            onClick={() => handleClick(sauceData, "sauce")}
          >
            <FontAwesomeIcon icon={faJar} />
            <p>Sosy</p>
          </div>
          <div
            style={
              activeMenu === "soda" ? { color: "rgba(174, 116, 3, 255)" } : {}
            }
            className="category--sodas"
            onClick={() => handleClick(sodaData, "soda")}
          >
            <FontAwesomeIcon icon={faGlassWater} />
            <p>Napoje</p>
          </div>
          <div
            style={
              activeMenu === "drink" ? { color: "rgba(174, 116, 3, 255)" } : {}
            }
            className="category--drinks"
            onClick={() => handleClick(drinkData, "drink")}
          >
            <FontAwesomeIcon icon={faMartiniGlass} />
            <p>Drinki</p>
          </div>
          <div
            style={
              activeMenu === "shot" ? { color: "rgba(174, 116, 3, 255)" } : {}
            }
            className="category--shots"
            onClick={() => handleClick(shotData, "shot")}
          >
            <FontAwesomeIcon icon={faVial} />
            <p>Szoty</p>
          </div>
        </div>
        {activeMenu === "burger" ? (
          <h2 className="fries">FRYTKI DO BURGERA + 9PLN</h2>
        ) : (
          <></>
        )}
        {activeMenu === "pizza" ? (
          <h2 className="fries">PIZZA W ROZMIARZE 30 CM!</h2>
        ) : (
          <></>
        )}
        <ol>
          {menu &&
            menu.map((item, index) => {
              return (
                <li key={index}>
                  <div className="name-price__container">
                    <p className="menu__item--name">{item.name && item.name}</p>
                    {item.price && (
                      <span className="menu__item--price">
                        {item.price.toFixed(2) + "PLN"}
                      </span>
                    )}
                    {item.smallPrice && (
                      <span className="pizza--price">
                        {item.smallPrice.toFixed(2) + "PLN"}
                      </span>
                    )}
                  </div>
                  <div className="ingredients__container">
                    <div>
                      <p>
                        {item.polishIngredients && item.polishIngredients + "."}
                      </p>
                      <p>
                        {item.englishIngredients &&
                          item.englishIngredients + "."}
                        {item.englishName && item.englishName}
                      </p>
                    </div>
                  </div>
                </li>
              );
            })}
        </ol>
      </section>
    </>
  );
}
