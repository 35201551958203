import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();

  return (
    <header>
      <ul className="header__navigation">
        <Link to="/">
          <li
            className={
              location.pathname && location.pathname === "/"
                ? "section--active"
                : ""
            }
          >
            home
          </li>
        </Link>
        <Link to="/menu">
          <li
            className={
              location.pathname && location.pathname === "/menu"
                ? "section--active"
                : ""
            }
          >
            menu
          </li>
        </Link>
        <Link to="/dowoz">
          <li
            className={
              location.pathname && location.pathname === "/dowoz"
                ? "section--active"
                : ""
            }
          >
            dowóz
          </li>
        </Link>
        <Link to="/galeria">
          <li
            className={
              location.pathname && location.pathname === "/galeria"
                ? "section--active"
                : ""
            }
          >
            galeria
          </li>
        </Link>
        <Link to="/kontakt">
          <li
            className={
              location.pathname && location.pathname === "/kontakt"
                ? "section--active"
                : ""
            }
          >
            kontakt
          </li>
        </Link>
      </ul>
    </header>
  );
}
