export const mainDishData = [
  {
    name: "SKRZYDEŁKA",
    polishIngredients: "Skrzydełka (6 szt.), frytki",
    englishIngredients: "Chicken wings (6 pcs.), fries",
    price: 33,
  },
  {
    name: "NUGGETSY",
    polishIngredients: "Nuggetsy (8 szt.), frytki, ketchup",
    englishIngredients: "Nuggets (8 pcs.), fries, ketchup",
    price: 30,
  },
  {
    name: "KANAPKA PANUOZZO PROSCIUTTO",
    polishIngredients:
      "Parmigiano reggiano, mozzarella w zalewie, prosciutto cotto, pomidorki koktajlowe, bazylia, rukola, oregano, oliwa z oliwek",
    englishIngredients:
      "Parmigiano reggiano, mozzarella in brine, prosciutto cotto, cherry tomatoes, basil, rocket/arugula, oregano, olive oil",
    price: 30,
  },
  {
    name: "KANAPKA PANUOZZO VEGE",
    polishIngredients:
      "Parmigiano reggiano, mozzarella w zalewie, oliwki, pomidorki koktajlowe, bazylia, rukola, cebula, oregano, oliwa z oliwek",
    englishIngredients:
      "Parmigiano reggiano, mozzarella in brine, olives, cherry tomatoes, basil, rocket/arugula, onion, oregano, olive oil",
    price: 30,
  },
  // {
  //   name: "MIRUNA W CIEŚCIE",
  //   polishIngredients: "Miruna w cieście piwnym (4 szt.), frytki, sałatka",
  //   englishIngredients: "4 pieces fish, french fries, salad",
  //   price: 28,
  // },
];
