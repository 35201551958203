import React from "react";
import Header from "../components/Header";
import { deliveryData } from "../data/deliveryData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmog, faCarSide, faHouse } from "@fortawesome/free-solid-svg-icons";

export default function Delivery() {
  return (
    <section className="section--delivery">
      <Header />
      <div className="delivery__container">
        <h1>Dowóz gratis przy zamówieniu powyżej:</h1>
        <ul className="delivery-towns__list">
          {deliveryData.map((item, index) => {
            return (
              <li className="delivery__row" key={index}>
                <span className="town">{item.town}</span>
                <span className="price">{item.price}PLN</span>
              </li>
            );
          })}
        </ul>
        <div className="delivery-icon__container">
          <div className="delivery-icon__container--left">
            <FontAwesomeIcon
              icon={faSmog}
              className="fa-icon--delivery fa-smog"
            />
            <FontAwesomeIcon
              icon={faCarSide}
              className="fa-icon--delivery fa-car"
            />
          </div>
          <FontAwesomeIcon
            icon={faHouse}
            className="fa-icon--delivery fa-house"
          />
        </div>
      </div>
    </section>
  );
}
