export const pizzaData = [
  {
    name: "MARGHERITA",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, parmigiano reggiano, bazylia",
    englishIngredients:
      "San marzano tomatoes, fior di latte mozzarella, parmigiano reggiano, basil",
    smallPrice: 32,
  },
  {
    name: "MARGHERITA DI BUFALA",
    polishIngredients:
      "Pomidory san marzano, mozzarella di bufala, parmigiano reggiano, bazylia",
    englishIngredients:
      "San marzano tomatoes, mozzarella di bufala, parmigiano reggiano, basil",
    smallPrice: 35,
  },
  {
    name: "DATTERINI GIALLI",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, parmigiano reggiano, bazylia, pomidorki żółte suszone na słońcu w oleju",
    englishIngredients:
      "San marzano tomatoes, mozzarella fior di latte, parmigiano reggiano, basil, sun-dried yellow tomatoes in oil",
    smallPrice: 34,
  },
  {
    name: "CRUDO",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, rukola, pomidorki koktajlowe, prosciutto crudo, parmigiano reggiano",
    englishIngredients:
      "San marzano tomatoes, mozzarella fior di latte, arugula, cherry tomatoes, prosciutto crudo, parmigiano reggiano",
    smallPrice: 36,
  },
  {
    name: "E-FUNGHI",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, pieczarki, prosciutto cotto, parmigiano reggiano, bazylia",
    englishIngredients:
      "San marzano tomatoes, mozzarella fior di latte, mushrooms, prosciutto cotto, parmigiano reggiano, basil",
    smallPrice: 36,
  },
  {
    name: "MARINARA",
    polishIngredients: "Pomidory san marzano, czosnek, oregano, oliwa z oliwek",
    englishIngredients: "San marzano tomatoes, garlic, oregano, olive oil",
    smallPrice: 27,
  },
  {
    name: "SPINACI",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, parmigiano reggiano, bazylia, salami milano, szpinak",
    englishIngredients:
      "San marzano tomatoes, mozzarella fior di latte, parmigiano reggiano, basil, salami milano, spinach",
    smallPrice: 37,
  },
  {
    name: "CARBONARA",
    polishIngredients:
      "Śmietanka, mozzarella fior di latte, pancetta, bazylia, cebula, parmigiano reggiano",
    englishIngredients:
      "Cream, mozzarella fior di latte, pancetta, basil, onion, parmigiano reggiano",
    smallPrice: 37,
  },
  {
    name: "PISTACCHIO",
    polishIngredients:
      "Pesto pistacjowe, śmietanka, mozzarella fior di latte, mortadela pistacjowa, burrata, parmigiano reggiano, bazylia",
    englishIngredients:
      "Pistachio pesto, cream, mozzarella fior di latte, pistachio mortadella, burrata, parmigiano reggiano, basil",
    smallPrice: 43,
  },
  {
    name: "4 SERY",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, parmigiano reggiano, bazylia, feta, gorgonzola",
    englishIngredients:
      "San marzano tomatoes, mozzarella fior di latte, parmigiano reggiano, basil, feta, gorgonzola",
    smallPrice: 38,
  },
  {
    name: "COTTO",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, prosciutto cotto, parmigiano reggiano, bazylia",
    englishIngredients:
      "San marzano tomatoes, mozzarella fior di latte, prosciutto cotto, parmigiano reggiano, basil",
    smallPrice: 34,
  },
  {
    name: "DIAVOLA",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, salami spianata piccante, burrata, bazylia",
    englishIngredients:
      "San marzano tomatoes, mozzarella fior di latte, spicy salami spianata, burrata, basil",
    smallPrice: 39,
  },
  {
    name: "SALAMI",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, parmigiano reggiano, bazylia, salami milano",
    englishIngredients:
      "San marzano tomatoes, mozzarella fior di latte, parmigiano reggiano, basil, salami milano",
    smallPrice: 36,
  },
  {
    name: "PANCETTA ARROTOLATA",
    polishIngredients:
      "Śmietanka, mozzarella fior di latte, parmigiano reggiano, pesto bazyliowe, pancetta, pieczarki",
    englishIngredients:
      "Cream, mozzarella fior di latte, parmigiano reggiano, basil pesto, pancetta, mushrooms",
    smallPrice: 39,
  },
  {
    name: "CHORIZO & GRUSZKA",
    polishIngredients:
      "Śmietanka, mozzarella fior di latte, gruszka, ser kozi, szarpane chorizo, balsam gruszkowy",
    englishIngredients:
      "Cream, mozzarella fior di latte, pear, goat cheese, pulled chorizo, pear balsam",
    smallPrice: 37,
  },
  {
    name: "CONTRASTO",
    polishIngredients:
      "Pomidory san marzano, mozzarella fior di latte, parmigiano reggiano, bazylia, rukola, spianata piccante, pomidorki koktajlowe, krem balsamiczny",
    englishIngredients:
      "San marzano tomatoes, mozzarella fior di latte, parmigiano reggiano, basil, arugula, spicy salami spianata, cherry tomatoes, balsamic cream",
    smallPrice: 37,
  },
];
