export const shotData = [
  {
    name: "KAMIKADZE(4SZT.)",
    polishIngredients: "Blue curacao, syrop cytrynowy, wódka",
    price: 22,
  },
  {
    name: "DARK QUEEN(4SZT.)",
    polishIngredients: "Sok malinowy, sok czarna porzeczka, wódka",
    price: 22,
  },
  {
    name: "OCEAN SHOT(4SZT.)",
    polishIngredients: "Blue curacao, tequila, syrop limonkowy",
    price: 22,
  },
  {
    name: "LEMON SHOT(4SZT.)",
    polishIngredients: "Syrop cukrowy, wódka cytrynowa, kwasek cytrynowy",
    price: 22,
  },
  {
    name: "WŚCIEKŁY PIES(1SZT.)",
    polishIngredients: "Wódka, syrop truskawkowy, tabasco",
    price: 12,
  },
  {
    name: "TEQUILA(1SZT.)",
    polishIngredients: "Tequila, cytryna, sól",
    price: 12,
  },
  {
    name: "BLUE TEQUILA(1SZT.)",
    polishIngredients: "Blue curacao, tequila",
    price: 12,
  },
  {
    name: "JAGER BULLET(1SZT.)",
    polishIngredients: "Jagermeister, wódka, sok pomarańczowy",
    price: 12,
  },
  {
    name: "CHMURKA(1SZT.)",
    polishIngredients: "Archers, wódka, śmietanka",
    price: 12,
  },
  {
    name: "MAŁPI MÓŻDŻEK(1SZT.)",
    polishIngredients: "Archers, Baileys, grenadyna",
    price: 12,
  },
  {
    name: "B-52(1SZT.)",
    polishIngredients: "Kahula, Baileys, triple sec",
    price: 12,
  },
];
