export const sodaData = [
  {
    name: "FUZETEA",
    price: 10,
  },
  {
    name: "CAPPY",
    price: 8,
  },
  {
    name: "COCA-COLA 0,5L",
    price: 10,
  },
  {
    name: "SPRITE 0,5L",
    price: 10,
  },
  {
    name: "FANTA 0,5L",
    price: 10,
  },
  {
    name: "MONSTER",
    price: 13,
  },
  {
    name: "WODA 0,5L",
    price: 7,
  },
];
