export const snackData = [
  {
    name: "FRYTKI",
    englishName: "Fries",
    price: 11,
  },
  {
    name: "KRĄŻKI CEBULOWE",
    englishName: "Onion rings",
    price: 12,
  },
  {
    name: "ZIEMNIACZKI PIECZONE",
    englishName: "Baked potatoes",
    price: 12,
  },
  {
    name: "SZYSZKI ZIEMNIACZANE",
    englishName: "Potato cones",
    price: 12,
  },
];
