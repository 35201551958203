export const drinkData = [
  {
    name: "MOJITO",
    polishIngredients: "Rum, cukier trzcinowy, mięta, woda, lód",
    price: 23,
  },
  {
    name: "SEX ON THE BEACH",
    polishIngredients:
      "Wódka, sok brzoskwiniowy, sok pomarańczowy, sok żurawinowy",
    price: 23,
  },
  {
    name: "JAGERBOMB",
    polishIngredients: "Jagermeister, Red Bull",
    price: 22,
  },
  {
    name: "WÓDKA + COLA",
    polishIngredients: "Wódka, coca-cola, cytryna",
    price: 20,
  },
  {
    name: "WHISKEY + COLA",
    polishIngredients: "Jack Daniels, coca-cola, cytryna",
    price: 23,
  },
  {
    name: "BACARDI + COLA",
    polishIngredients: "Rum, coca-cola, cytryna",
    price: 20,
  },
  {
    name: "TROPICAL SUNRISE",
    polishIngredients:
      "Wódka, wódka truskawkowa, malibu, sok żurawinowy, sok z granata",
    price: 30,
  },
  {
    name: "CUBA LIBRE",
    polishIngredients: "Rum, sok z limonki, coca-cola",
    price: 21,
  },
  {
    name: "TEQILA SUNRISE",
    polishIngredients: "Tequila, sok pomarańczowy, grenadyna",
    price: 21,
  },
  {
    name: "APEROL SPRITZ",
    polishIngredients: "Aperol, Prosecco, woda gazowana",
    price: 25,
  },
  {
    name: "WHISKEY SOUR",
    polishIngredients: "Whiskey, sok z cytryny, syrop cukrowy, białka jaj",
    price: 25,
  },
  {
    name: "TOM COLLINS",
    polishIngredients: "Gin, sok z cytryny, syrop cukrowy, woda gazowana",
    price: 22,
  },
  {
    name: "LONG ISLAND ICED TEA",
    polishIngredients:
      "Wódka, tequila, rum, gin, triple sec, sok z cytryny, syrop cukrowy, cola",
    price: 35,
  },
  {
    name: "BLUE LONG ISLAND",
    polishIngredients:
      "Wódka, tequila, rum, gin, triple sec, sok z cytryny, syrop cukrowy, sprite, blue curacao",
    price: 35,
  },
  {
    name: "MARGARITA",
    polishIngredients: "Tequila, triple sec, sok z limonki",
    price: 21,
  },
  {
    name: "COSMOPOLITAN",
    polishIngredients:
      "Wódka cytrynowa, triple sec, sok z limonki, sok żurawinowy",
    price: 21,
  },
  {
    name: "ORGASM",
    polishIngredients: "Baileys, kahlua, śmietanka",
    price: 22,
  },
  {
    name: "DAIQUIRI",
    polishIngredients: "Rum, sok z limonki, syrop cukrowy",
    price: 19,
  },
  {
    name: "WHITE RUSSIAN",
    polishIngredients: "Wódka, likier kawowy, śmietanka",
    price: 22,
  },
  {
    name: "MANHATTAN",
    polishIngredients: "Whiskey, czerwone martini, Angustura",
    price: 20,
  },
  {
    name: "PORNSTAR MARTINI",
    polishIngredients:
      "Wódka waniliowa, likier marakuja, syrop z limonki, prosecco, marakuja",
    price: 25,
  },
];
