import React from "react";
import Header from "../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <section className="section--home">
      <Header />
      <div className="home-content__container">
        <img
          className="logo--home"
          src={require("../images/logo.png")}
          alt="k2-logo"
        />
        <div className="description__container">
          <h1 className="description--home">
            Chcesz dobrze zjeść lub spędzić czas z bliskimi? Żaden problem!
            Oferujemy pyszne jedzenie i niepowtarzalną atmosferę, sprawdź jak to
            u nas wygląda
          </h1>
          <Link to="/galeria">
            <button>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="fa-icon description__link arrow1"
              />
              <FontAwesomeIcon
                icon={faArrowRight}
                className="fa-icon description__link arrow2"
              />
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
